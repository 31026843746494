import React, { useEffect, useState } from "react";
import {  useDispatch } from "react-redux";
import { UserActions } from "../../store/action";

const Captcha = (props) => {

    const [captcha, setCaptcha] = useState('')
    const [captchaInvalid, setCaptchaInvalid] = useState('')
    const [captchaImg, setCaptchaImg] = useState({})

    const dispatch = useDispatch();

    useEffect(()=>{

        loadCaptchaImage()
         // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.reloadCaptcha])

    
    useEffect(()=> {
        setCaptchaInvalid(props.errField.captcha)
    }, [props.errField])


    /**
     *  Load Captcha Image
     */

    const loadCaptchaImage = (err = []) => {
        setCaptcha("");

        dispatch(UserActions.loginCaptcha()).then(res=>{
            if(res && res.data && res.data.data){
                setCaptchaImg(res.data.data)
            }
        })
                
    };

    /**
     * Input Handler
     * @param e (synthatic element)
     */
    const handleInputChange = async e => {
        let captchaVal = e.target.value;

        setCaptcha(captchaVal)

        props.onKeyPressCaptcha(captchaVal);        
    };

    /**
     * Reset Captcha Error
     */
    // const resetCaptchaError = async () => {
    //     if(this._isMount){
    //     await this.setState({
    //         captchaInvalid: ""
    //     });
    //     }
        
    // };


    const { captchaError } = props;
    
    return (
      <>
        <div className={captchaInvalid ? "material error": "material"}>

          <input
            id = "captcha"
            type="text"
            placeholder=" "
            name="captcha"
            value={captcha}
            className="form-input"
            onChange={handleInputChange}
          />

                <i className="ic-survey icn-login"></i>

                <label data-label="Captcha" className="form-label"></label>
                <span className="erroe-txt">{captchaInvalid||''}</span>

                <span className="password-show cap-img" style={{ "fontSize": '20px'}}>
                  {captchaError ? (
                    ''
                  ) : (
                    <>
                      <img src= {`data:image/svg+xml;base64,${window.btoa(
                        captchaImg.image
                    )}`}
                      className="captchaimg" alt="" style={{width:'80%'}}/>
                      <span
                        className="reload"
                        onClick={loadCaptchaImage}
                        title="reload captcha"
                      >
                        &#x21bb;
                      </span>
                    </>
                  )}
                </span>
          
        </div>
      </>
    );
}

export default Captcha;
