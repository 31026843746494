import React, { Component } from "react";
import { NavLink } from 'react-router-dom';
//import { Accordion, Card, Button, DropdownButton, Dropdown } from "react-bootstrap";
import Notifaction from './Notification'
import logo_img from "../../webroot/images/logo.svg";
import secureStorage from '../../config/encrypt';
import { withTranslation } from 'react-i18next';
import { toast } from "react-toastify";
import { Navigate } from "react-router";
import * as HELPER from '../../config/helper';
import powerBiLogo from "../../webroot/images/Power-BI-Logo.png";
import iconBar from "../../webroot/images/icon-bars.svg";


// const Navigation = () => {

//     this.state = {
//         activeId: null  // nothing selected by default, but this is up to you...
//     }

//     handleClick(event, id) {
//         this.setState({ activeId: id })
//     }

class Navigation extends Component {
    _isMount = false
    constructor(props) {
        super(props);
        this.state = {
            activeId: null,  // nothing selected by default, but this is up to you...
            redirect: false,
            name: "",
            role: "",
            isOpen : false,
            showDropDown : false
        }
    }
    componentDidMount = () => {
        this._isMount = true
        let result = secureStorage.getItem("authUserInfo");

        if (!result) {
            this.setState({ redirect: true });
        } else {
            let userInfo = result.user_data;
            this.setState({ name: userInfo.name || 'User', role: userInfo.role });
        }
    }

    handleClick(event, id) {
        this.setState({ activeId: id })
    }
    changeLanguage = (lang) => {
        if (this._isMount) {
            secureStorage.setItem('lang', lang);
            this.props.i18n.changeLanguage(lang)
        }
    }
    logOutUser = async () => {
        let result = secureStorage.getItem("authUserInfo");
        if (result) {
            secureStorage.removeItem("authUserInfo");
            toast.success(`LogOut Successfully`);
            this.setState({ redirect: true });
        }
    };
    checkVisibleMenu = (menus) => {
        return menus.filter(menu => menu.show_in_menu)
    }
    linkUrl = (menu) => {
        let menuLink = '#'
        if (menu['action']) {
            menuLink = '/' + menu['action']
        }
        return menuLink
    }
    getCurrentClass = (menuData) => {
        let currentPath = window.location.pathname.split('/');
        let activeClass = '';
        currentPath = currentPath.filter(path => path)
        if (currentPath.length) {
            let stringMenuData = JSON.stringify(menuData)
            let checkActiveUrl = HELPER.isUserHasAccessPage(currentPath[0], stringMenuData)
            if (checkActiveUrl) activeClass = 'active';
        } else {
            if (menuData.name === 'Dashboard') {
                activeClass = 'active';
            }
        }
        return activeClass;
    }

    onClickClass = () => {  
        let isOpen = this.state.isOpen; 
        this.setState({isOpen : !(isOpen)});
    }

    showOptions = () => { 
        let showDropDown = this.state.showDropDown;
        console.log("shosdjasd------------", showDropDown)
        this.setState({showDropDown : !showDropDown});
    }

    closeNav = () =>{
        this.setState({ isOpen: false }); 
    }

    render() {
        const { name, role, isOpen, showDropDown } = this.state;
        let language = secureStorage.getItem("lang") === "id" ? "ID" : "EN";
        const authUserInfo = secureStorage.getItem('authUserInfo') || {};

        let isAuthorized = (authUserInfo && authUserInfo['headerMenu']) ? HELPER.isUserHasAccessPage() : true
        if (this.state.redirect || !isAuthorized) {
            secureStorage.removeItem("authUserInfo");
            return <Navigate to="/login" />;
        }

        const menuHeaders = authUserInfo && authUserInfo.headerMenu ? JSON.parse(authUserInfo.headerMenu) : [];
        const visibleMenus = this.checkVisibleMenu(menuHeaders);

        let userReportAccess = (authUserInfo?.user_data?.report_access) ? JSON.parse(authUserInfo?.user_data?.report_access) : {};

        return (
            <div className="container-fluid" >
                <div className="header-main web-only">
                    <div className="logo">
                        {/* <img src={logo_img} className="" alt='incentive' /> */}
                    </div>
                    <div className="right-panel-naviganion menupanel">
                        <div className="nav">
                            <ul>
                                {
                                    visibleMenus && (visibleMenus).length
                                        ?
                                        (visibleMenus).filter(ob => ob.action != 'report').map((el, i) => {

                                            return (<li className={el.childMenu.length && !el['action'] ? 'dropdownmenu' : ''} key={i}>

                                                <NavLink to={this.linkUrl(el)} className={({ isActive, isPending }) => isPending ? "pending" : isActive ? "active" : ""}>
                                                    {this.props.t('MENU.' + (el.name).replaceAll(' ', '_'))}
                                                </NavLink>

                                                <ul className="submenu" key={i}>
                                                    {
                                                        this.checkVisibleMenu(el['childMenu']).map((elm, j) => {
                                                            let childMenuName = elm['name'].replaceAll(' ', '_').toLowerCase()
                                                            return (<li className="level2" key={j}>

                                                                <NavLink to={this.linkUrl(elm)} className={this.getCurrentClass(elm)}>{this.props.t('MENU.' + childMenuName)}</NavLink>
                                                                {/* <ul className="submenu" >
                                                                        
                                                                        {
                                                                            
                                                                            this.checkVisibleMenu(elm['childSubMenu']).map((elmn,k)=>{
                                                                                let subMenuName = elmn['name'].replace(' ', '_').toLowerCase()

                                                                                    return (<li key={k}><NavLink to={this.linkUrl(elmn)}>{this.props.t('menu.'+subMenuName)}</NavLink></li>)
                                                                            })
                                                                        }
                                                                    </ul> */}
                                                            </li>)
                                                        })
                                                    }
                                                </ul>
                                            </li>)

                                        })
                                        :
                                        (
                                            <li></li>
                                        )
                                }
                                {/* <li><NavLink to="/dashboard">Dashboard</NavLink></li>
                                <li><NavLink to="/incentive">Config Panel </NavLink></li> */}

                                {userReportAccess && userReportAccess.report_access && userReportAccess.report_access.length > 0 ?
                                    <li className="dropdownmenu"><NavLink>Report</NavLink>
                                        <ul className="submenu">
                                            {userReportAccess.report_access.map((report, index) =>
                                            (<li className="level2" key={index} >
                                                <NavLink to={`/report/${index}`} className={'active'}> {report.label}</NavLink>
                                            </li>)
                                            )}
                                        </ul>
                                    </li> : null}
                            </ul>
                        </div>
                        {/* <a className="notification-btn" href="/#" >
                            <Notifaction />

                        </a> */}

                        <div className="user-detail ">
                            <ul>

                                <li className="dropdownmenu">
                                    <NavLink to="#">
                                        <span className="userarc">
                                            {name.length && name[0].toUpperCase()}
                                        </span>
                                        <div className="username">
                                            <span>{name}</span>
                                            <span className="user-degintion">{role}</span>
                                        </div>
                                    </NavLink>
                                    <ul className="submenu">
                                        <li><NavLink to="#" onClick={this.logOutUser.bind()}>Log Out</NavLink> </li>
                                    </ul>
                                </li>

                            </ul>
                        </div>
                        {/* 
                        <div className="language">
                            <ul>
                                <li className="dropdownmenu">
                                    <NavLink to="#">
                                        {language}
                                    </NavLink>
                                    <ul className="submenu lang-menu">
                                        <li><NavLink to="#" onClick={() => this.changeLanguage('en')}>EN</NavLink></li>
                                        <li><NavLink to="#" onClick={() => this.changeLanguage('id')}>ID</NavLink></li>
                                    </ul>
                                </li>

                            </ul>
                        </div> */}
                    </div>

                </div>






            
                <div className="wap-header wap-only">
                    <ul className="header-ul">
                        <li className="logo-mob" data-nav-trigger="main"> 
                            <i className="ic-menu" onClick={() => this.onClickClass()}></i> 
                            <img src={powerBiLogo} width="52" height="24" alt="Oto" /> 
                        </li> 
                        <li className="user-info">
                         {name} 
                        </li>  

                    </ul>
                </div>
                <nav className={isOpen ? "hamburger animate active" : "hamburger animate"}>
                    <i className="ic-clearclose d-flex align-center justify-content-center" onClick={() => this.onClickClass()}></i>
                    <ul>
                        <li>
                            <NavLink to="/dashboard" onClick={this.closeNav}> 
                                Dashboard
                            </NavLink>
                        </li>
                        {userReportAccess && userReportAccess.report_access && userReportAccess.report_access.length > 0 ?
                                    <li className={showDropDown ? "optionsubmenu active" : "optionsubmenu"} onClick={() => this.showOptions()}>  
                                        <NavLink>Report</NavLink>
                                        <ul>
                                            {userReportAccess.report_access.map((report, index) =>
                                            (<li key={index} >
                                                <NavLink to={`/report/${index}`} className={'active'} onClick={this.closeNav}> {report.label}</NavLink>
                                            </li>)
                                            )}
                                        </ul>
                                    </li> : null}
                     {/*    <li className={showDropDown ? "optionsubmenu active" : "optionsubmenu"} onClick={() => this.showOptions()}>        
                            <a href="javascript:void(0)">
                                Report
                            </a>
                            <ul>
                          
                                <li>
                                    <a>
                                        En
                                    </a>
                                </li>
                                <li>
                                    <a>
                                        Th
                                    </a>
                                </li>
                            </ul>
                        </li> */}
                        <li><NavLink to="#" onClick={this.logOutUser.bind()}>Log Out</NavLink> </li>

                    </ul>
                </nav>
                <aside className={isOpen ? "menu-overlay active" : "menu-overlay"} onClick={() => this.onClickClass()}></aside>



            </div >
        );
    }
}
export default withTranslation('common')(Navigation); 