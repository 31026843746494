import secureStorage from "./encrypt";
import _ from "lodash";
export const API_URL = {
  GETWAY_API: process.env.REACT_APP_GETWAY_API,
  COMMON_GATEWAY_API: "https://int-gcloud-stage.oto.com/",
  API_KEY: "agentbox-ac1faa7b-9fe9-4483-9525-5cc4ce94c639"
};

export const SETTING = {
  CRYPTO_KEY: "U*oDwrIU!B#QX37%#24",
  RESEND_OTP_TIME_IN_SECOND: 30,
};
export const NAME_VALIDATION = /^[a-zA-Z ]+$/;

export const ALPHA_NUMERIC_VALIDATION = /^[a-zA-Z0-9 ]+$/;

export const ACCOUNT_NO_VALIDATION = /^\d{10,16}$/;

export const SPOUSE_OFFICE_ADDRESS_VALIDATION = /^[a-zA-Z0-9@%_&*$#!~()<>?+:,;=^?|'-<>.{} ]{1,70}$/;

export const SPOUSE_BUSINESS_VALIDATION = /^[a-zA-Z0-9@%_&*$#!~()<>?+:,;=^?|'-<>.{} ]{1,20}$/;

export const EMERGENCY_CONTACT_NAME_VALIDATION = /^[a-zA-Z0-9@%_&*$#!~()<>?+:,;=^?|'-<>.{} ]{1,50}$/;

export const EMERGENCY_CONTACT_ADDRESS_VALIDATION = /^[a-zA-Z0-9@%_&*$#!~()<>?+:,;=^?|'-<>.{} ]{1,70}$/;

export const MOBILE_VLIDATION_RULE = /^[8]\d{8,11}$/;

export const MARGIN_FOR_OTO_MAX_VALIDATION = 1000000;

export const IS_USER_ACCESS_PAGE = (controller, action) => {
	let isUserHasRights = false, isEditAccess = false;
	const userAccessFeatures = secureStorage.getItem("userAccess");
	if (userAccessFeatures && userAccessFeatures.length) {
		let isKeyExist = _.findKey(userAccessFeatures, function (v) {
			if (controller && v.controller && action && v.action) {
				return (
					v.controller.toLowerCase() === controller.toLowerCase() && 
					v.action.toLowerCase() === action.toLowerCase()
				);
			}
		});
		isUserHasRights = isKeyExist ? true : false;
		isEditAccess = isKeyExist ? ((Number(userAccessFeatures[isKeyExist].access_type) === 1) ? true : false) : false
	}

	return {
		is_access_page: isUserHasRights,
		is_edit_access: isEditAccess
	};
};

export const APIV = 2;
    
 