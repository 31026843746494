import { UserService } from '../../services';
import { UserConstants } from '../constants/user.constants';
import { toast } from 'react-toastify';
import secureStorage from '../../config/encrypt';

export const UserActions = {
    createOtp,
    submitBulkUploadIncentive,
    VerifyOtp, 
    loginCaptcha,
    submitPdfUpload
};

function VerifyOtp(params) {

    return dispatch => {
        return new Promise((resolve, reject) => {
            UserService.VerifyOtp(params)
                .then(
                    user => {
                        if (user && user.data && user.data.status === 200) {

                            let ob = user.data.data;
                            secureStorage.setItem('authUserInfo', (ob));
                            toast.success(user.data.message);
                            dispatch(success(ob));
                            return resolve(user.data)
                        }
                        else {
                            dispatch(failure('Invalid credentials '));
                            toast.error(user.data.message);
                            return resolve(user && user.data)
                        }
                    },
                    error => {
                        dispatch(failure(error));
                        return reject(error);
                    }
                );
        });
    };

    function success(user) { return { type: UserConstants.LOGIN_SUCCESS, user } }
    function failure(error) { return { type: UserConstants.LOGIN_FAILURE, error } }

}

function submitBulkUploadIncentive(postData, headers) {

    return dispatch => {
        return new Promise((resolve, reject) => {
            UserService.submitBulkUploadIncentive(postData, headers)
                .then(
                    resp => {
                        if (resp && resp.data && resp.data.status === 200) {
                            return resolve(resp.data)
                        }
                        else {
                            reject((resp && resp.data) || []);
                        }
                    },
                    error => {
                        return reject(error)
                    }
                );
        });
    };

}

function createOtp(params) {

    return dispatch => {
        return new Promise((resolve, reject) => {
            UserService.createOtp(params)
                .then(
                    user => {
                        if (user && user.data && user.data.status === 200) {
                            toast.success(user.data.message);
                            return resolve(user.data)
                        }
                        else {
                            toast.error(user.data.message);
                            return resolve(user && user.data)
                        }
                    },
                    error => {
                        return reject(error);
                    }
                );
        });
    };

}

function loginCaptcha() {
    return dispatch => {
        return new Promise((resolve, reject) => {

            UserService.loginCaptcha()
                .then(
                    captcha => {
                        if (captcha) {
                            if (captcha.data && captcha.data.status === 200) {
                                dispatch(success(captcha.data.data));
                            } else {
                                dispatch(failure(captcha.data.message));
                            }
                        } else {
                            dispatch(failure('error'));
                        }

                        resolve(captcha)
                    },
                    error => {
                        dispatch(failure(error));
                        reject(error)
                    }
                )
        })
    }

    function success(captcha) { return { type: UserConstants.LOGIN_CAPTCHA, captcha } }
    function failure(error) { return { type: UserConstants.LOGIN_CAPTCHA, error } }
}

function submitPdfUpload(postData, headers) {

    return dispatch => {
      return new Promise((resolve, reject) => {
        UserService.submitPdfUpload(postData, headers)
          .then(
            resp => {
              if(resp && resp.data && resp.data.status === 200){
                return resolve(resp.data)
              }
              else{
                reject((resp && resp.data) || []);
              }
            },
            error => {
              return reject(error)
            }
          );
      });
    };
  
  }


  