import React from 'react';
import secureStorage from '../../config/encrypt';
import { NavLink } from 'react-router-dom';

const CARD_COLOR = {
  0:'color-one',
  1:'color-two',
  2:'color-three',
  3:'color-four'
}

function Dashboard() {

  const authUserInfo = secureStorage.getItem('authUserInfo') || {};

  let userReportAccess = (authUserInfo?.user_data?.report_access) ? JSON.parse(authUserInfo?.user_data?.report_access) : {}; 

  return (
    <div className="dashboard-bg">
      <div className="dashboard-outer">
        <div className="heading">
          <span className="subheading">Hello {authUserInfo?.user_data?.name || ''}</span>
          <h1>Welcome to Cardekho Reports</h1>
        </div>

        <div className="dashboard-toggle-tab">
          <div className="dashboard-card">


            {
              userReportAccess?.report_access?.length && userReportAccess.report_access.map((item, index) => {
                let colors = (index > 4) ?  CARD_COLOR[(index%4)] : CARD_COLOR[index];
                return (<NavLink to={`/report/${index}`} className={'active'} key={index}>  
                 <div className={"card "+colors }> 
                  <div className="roung-bg">
                    <i className="ic-application"></i>
                  </div>
                  <span className="count-txt"></span>
                  <span className="card-txt">{item.label}</span>
                </div>  </NavLink>)
              })
            } 

          </div>
        </div>


      </div>
    </div>
  )
}

export default Dashboard