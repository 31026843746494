import React, { useState, useEffect } from 'react'
import { useTranslation } from "react-i18next";
import { useDispatch } from 'react-redux';
import { UserActions } from "../../store/action";
import { useNavigate } from "react-router-dom";

const VerifyOtp = (props) => {
    const maxTime = 59;
    const [errField, setErrField] = useState({});
    const [inputField, setInputField] = useState({
        ...props.inputField, otp: ""
    });
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const history = useNavigate();

    const { t } = useTranslation('common');
    useEffect(()=>{
        setInputField({ ...inputField, user_id : props.userId })
        // eslint-disable-next-line 
    },[props.userId])

    const [otpTimer, setOtpTimer] = useState(maxTime);

    const inputHandler = (e) => {
        setInputField({ ...inputField, [e.target.name]: e.target.value });
    };


    useEffect(() => {
        let startInterval;
        if (otpTimer >= 0) {

            startInterval = setInterval(() => {
                setOtpTimer((val) => val - 1)

            }, 1000)
        } else {
            clearInterval(startInterval)
            setOtpTimer(-1)
        }

        return () => {
            clearInterval(startInterval)

        }
    }, [otpTimer])

    const submitButton = async () => {

        if (validForm()) {
            setLoading(true) 

            dispatch(UserActions.VerifyOtp(inputField)).then((res) => {
                 setLoading(false) 
                if (res && res.status === 200) { 
                    history("/dashboard")
                }
            });
        } else {
            setLoading(false);
        }
    };

    //For validation

    const validForm = () => {
        let formIsValid = true;
        setErrField({
            captchErr: ""
        });
        if (inputField.otp === "" || inputField.otp.trim() === '') {
            formIsValid = false;
            setErrField((prevState) => ({
                ...prevState,
                otp: t('VALIDATION.OTP'),
            }));
        }
        return formIsValid;
    };

    /**
     * Resend OTP Handler
     */
    const resendOtp = () => {
        if (otpTimer <= 0) {
            dispatch(UserActions.createOtp({ ...inputField, resendOtp: true })).then(res => setOtpTimer(maxTime))
        }
    }

    return (
        <div>
            <fieldset className="">
                <div className={"material " + (errField.otp ? "error" : "")}    >
                    <input
                        type="text"
                        placeholder=" "
                        name="otp"
                        value={inputField.otp}
                        className={"form-input "+ (resendOtp?'resendOtpActive':'')}
                        onChange={inputHandler}
                        maxLength={6}
                    />
                    <i className="ic-otp icn-login">
                        <i className='path1'></i>
                        <i className='path2'></i>
                        <i className='path3'></i>
                        <i className='path4'></i>
                    </i>

                    <label data-label={t('FORM.OTP')} className="form-label"></label>
                    {errField.otp && (
                        <span className={"erroe-txt " + (resendOtp?'resendOtpActiveError':'')}>
                            {errField.otp}
                        </span>
                    )}
                </div>
                {
                    <p onClick={resendOtp} >{otpTimer < 0 ? 'Resend Otp' : 'resend otp after '+(otpTimer > 9 ? "0:" + otpTimer : "0:0" + otpTimer)+" seconds" }</p>
                }


            </fieldset>

            <button type="button" className="btn-primary" onClick={submitButton} disabled={loading}>
                {t('FORM.LOGIN')}
            </button>

        </div>
    )
}

export default VerifyOtp