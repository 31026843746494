import Login from "../view/auth/Login"
import Dashboard from "../view/dashboard/Dashbaord"  
import NotFoundPage from "../view/common/404Page";
import { useRoutes } from "react-router-dom"; 
import ReportView from "../view/dashboard/ReportView";
 
function AppRoutes() {
	let Router = useRoutes([
		{ path: "/",  element: <Dashboard /> }, 
		{ path: "/login", element: <Login /> },
		{ path: "/dashboard", element: <Dashboard /> }, 
		{ path: "/report/:clicked_index", element: <ReportView /> }, 
		{ path: "*", element: <NotFoundPage /> },
	  ]);
	return Router;
}


  export default AppRoutes;
