export const CommonConstants = {
    STATE_LIST_REQUEST: 'STATE_LIST_REQUEST',
    USER_LIST_REQUEST: 'USER_LIST_REQUEST',
    SCHEME_LIST_REQUEST: 'SCHEME_LIST_REQUEST',
    GET_ROLE_LIST:'GET_ROLE_LIST',
    FINANCIER_LIST_REQUEST: 'FINANCIER_LIST_REQUEST',
    ROLEWISE_SCHEME_LIST:'ROLEWISE_SCHEME_LIST',
    BM_USER_LIST:"BM_USER_LIST",
    ROLE_PRODUCTS_LIST: "ROLE_PRODUCTS_LIST",
    APPROVE_BUTTON: "APPROVE_BUTTON",
    ADMIN_DASHBOARD: "ADMIN_DASHBOARD",
    PAYOUT_DETAILS: "PAYOUT_DETAILS",
    INCENTIVE_CONFIG_FILTERS: "INCENTIVE_CONFIG_FILTERS",
    CSV_LOG_HISTORY:"CSV_LOG_HISTORY",
    SAVE_INCENTIVE_USER_DATA:"SAVE_INCENTIVE_USER_DATA",
    GET_MULTISELECTED_PAYOUTS: "GET_MULTISELECTED_PAYOUTS",
    LEAD_COUNT_PAYOUT_DETAILS:"LEAD_COUNT_PAYOUT_DETAILS",
    SET_MULTISELECTED_EXCLUDED_PAYOUTS: "SET_MULTISELECTED_EXCLUDED_PAYOUTS",
    CHECKED_ALL_STATUS:"CHECKED_ALL_STATUS",
    CHECK_FOR_MAIL_APPROVAL : "CHECK_FOR_MAIL_APPROVAL",
    DEALER_DETAILS: "DEALER_DETAILS",
    DEALER_CONFIGS : "DEALER_CONFIGS",
    DEALER_CATEGORY_DATA : "DEALER_CATEGORY_DATA",
    FINANCIER_GROUP_CONFIG : "FINANCIER_GROUP_CONFIG",
    DEVIATION_LIMIT_CONFIG : "DEVIATION_LIMIT_CONFIG"
};