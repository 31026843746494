import React, { useState, useEffect } from 'react';
import { models } from 'powerbi-client';
import { PowerBIEmbed } from 'powerbi-client-react';
import { useParams,useNavigate } from "react-router-dom";
import MasterService from './../../services/MasterService';
import { toast } from 'react-toastify';
import secureStorage from '../../config/encrypt';
import FullPageLoader from '../common/FullPageLoader';

const PowerBiReportView = () => {
  const { clicked_index } = useParams();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate()

  const [reportConfig, setReportConfig] = useState({
    type: 'report',
    embedUrl: '',
    accessToken: '',
    id: '',
    tokenType: models.TokenType.Embed,
    settings: {
      panes: {
        filters: {
          expanded: false,
          visible: false,
          bars: {
            statusBar: {
              visible: true
            }
          }
        }
      },
      background: models.BackgroundType.Transparent,
    }
  });

  let user_info = secureStorage.getItem("authUserInfo");
  let userReportAccess = (user_info?.user_data?.report_access) ? JSON.parse(user_info?.user_data?.report_access) : [];

  useEffect(() => {
    if(userReportAccess?.report_access?.length  > clicked_index ){
      getPowerBiData(); 
    }else{
      setTimeout(() => {
        navigate('/dashboard')
      }, 5000)
    }  
  }, [clicked_index]);
 

  const getPowerBiData = async () => {
    setLoading(true)

    let powerBiInfo = userReportAccess.report_access[clicked_index];
    if (powerBiInfo) {
      const { report_id, workspace_id } = powerBiInfo;
      let formData = { 'report_id': report_id, 'workspace_id': workspace_id }
      let powerBIRes = await MasterService.getPowerBiAccess(formData, { 'Content-Type': 'application/json' });
      setLoading(false)
      if (powerBIRes && powerBIRes.status && powerBIRes.status == '200') {
        setReportConfig({
          ...reportConfig,
          embedUrl: powerBIRes.data.data.embedUrl[0].embedUrl,
          accessToken: powerBIRes.data.data.accessToken,
          id: powerBIRes.data.data.embedUrl[0].reportId
        });
      } else {
        toast.error("You don't have PowerBI access.");
      }
    } else {
      setLoading(false)
    }

  };
 
  return (<>
    <div>
      <FullPageLoader show={loading} />
      {
        userReportAccess?.report_access?.length  > clicked_index ? 
          <PowerBIEmbed
            embedConfig={reportConfig}
            cssClassName='power-bi-report-class'
          />
          :
          <div className="dashboard-outer" style={{ marginTop: "100px", fontSize: "25px" }}>
          <h1 style={{color:'red'}}> <center> No report exists or you don't have permission to access this report! </center></h1><br/>
        </div> 

      }

    </div>
  </>)
}

export default PowerBiReportView;
